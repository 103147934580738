import React from "react"
import styled from "styled-components"

import ContactInfo from "./contact-info"
import ContactUsMap from "./contact-us-map"
import ContactUsForm from "./contact-us-form"

const ContactUsWrapperSingle = ({ className = "", contactInfo }) => {
  const address = contactInfo?.address?.google_maps_address
  const mapLink = `https://www.google.com/maps/embed/v1/place?key=${process.env.GATSBY_GOOGLE_MAPS_EMBED_KEY}&q=place_id:${address}`
  return (
    <ContactUsStyling className={className}>
      <DetailsSection>
        <h1 style={{ marginTop: "50px" }}>Contact us</h1>
        <ContactInfo contactInfo={contactInfo}></ContactInfo>
        <MapSection className="mobile">
          <ContactUsMap mapLink={mapLink}></ContactUsMap>
        </MapSection>
        <ContactUsForm></ContactUsForm>
      </DetailsSection>
      <MapSection className="desktop">
        <ContactUsMap mapLink={mapLink}></ContactUsMap>
      </MapSection>
    </ContactUsStyling>
  )
}

export default ContactUsWrapperSingle

const ContactUsStyling = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`

const DetailsSection = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: 0 50px 0 50px;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    padding: 0 30px 0 30px;
  }
`

const MapSection = styled.div`
  width: 40%;
  &.desktop {
    display: block;
  }
  &.mobile {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    margin-top: 120px;
    height: 400px;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
    margin-top: 0;
    margin-bottom: 50px;

    &.desktop {
      display: none;
    }
    &.mobile {
      display: block;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin: 0 -30px 50px -30px;
    width: calc(100% + 60px);
  }
`
