import React from "react"
import styled from "styled-components"

const ContactUsTextArea = ({ label, name = "" }) => {
  return (
    <Container>
      <h6>{label}</h6>
      <Input name={name}></Input>
    </Container>
  )
}

export default ContactUsTextArea

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`
const Input = styled.textarea`
  width: 100%;
  height: 120px;
  background-color: ${({ theme }) => theme.colors.brandGrey};
  border: none;
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.brandHover};
    outline: none;
  }
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 20px;
`
