import React from "react"
import styled from "styled-components"

import PhoneIcon from "../../images/icons/feather-phone"
import PinIcon from "../../images/icons/feather-map-pin"
import ClockIcon from "../../images/icons/feather-clock"
import MailIcon from "../../images/icons/feather-mail"

const ContactInfo = ({ contactInfo }) => {
  return (
    <Container>
      <ContactInfoSection>
        <ContactInfoItem className="left">
          <PhoneIcon />
          <ContactInfoContent>
            {contactInfo?.contact_details?.telephones.map((contact, index) => (
              <ContactInfoLine key={index}>
                <h6 className="contact-number">{contact?.telephone_label}</h6>
                <a href={`tel:${contact?.telephone_number}`}>
                  <StyledP>{contact?.telephone_number}</StyledP>
                </a>
              </ContactInfoLine>
            ))}
          </ContactInfoContent>
        </ContactInfoItem>
        <ContactInfoItem className="right">
          <PinIcon />
          <ContactInfoContent>
            <ContactInfoLine>
              <h6>{contactInfo?.contact_details?.name}</h6>
            </ContactInfoLine>
            <ContactInfoLine>
              <a
                href={contactInfo?.address?.address_link}
                target="_blank"
                rel="noreferrer"
              >
                <StyledP>{contactInfo?.address?.street_address}</StyledP>
              </a>
            </ContactInfoLine>
          </ContactInfoContent>
        </ContactInfoItem>
      </ContactInfoSection>
      <ContactInfoSection>
        <ContactInfoItem className="left service-hours">
          <ClockIcon />
          {contactInfo?.service_hours.map((item) => (
            <ContactInfoContent>
              <ContactInfoLine>
                <h6 className="service-hour">{item.days}</h6>
              </ContactInfoLine>
              <ContactInfoLine>
                <a
                  href={contactInfo?.address?.address_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledP>{item.hours}</StyledP>
                </a>
              </ContactInfoLine>
            </ContactInfoContent>
          ))}
        </ContactInfoItem>
        <ContactInfoItem className="right">
          <MailIcon />
          <ContactInfoContent>
            <ContactInfoLine>
              <h6>Information</h6>
            </ContactInfoLine>
            <ContactInfoLine>
              <a href={`mailto:${contactInfo?.contact_details?.contact_email}`}>
                <StyledP>{contactInfo?.contact_details?.contact_email}</StyledP>
              </a>
            </ContactInfoLine>
          </ContactInfoContent>
        </ContactInfoItem>
      </ContactInfoSection>
    </Container>
  )
}

export default ContactInfo

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 20px;
`

const ContactInfoSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
`

const ContactInfoItem = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: flex-start;
  flex-wrap: wrap;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    flex-wrap: nowrap;

    &.service-hours {
      flex-wrap: wrap;
    }
  }

  &.left {
    width: 450px;
    min-width: 300px;
  }

  &.right {
    width: 400px;
  }

  & > svg {
    margin-top: 10px;
    min-width: 18px;
    g,
    path {
      stroke: ${({ theme }) => theme.colors.brandPrimary};
    }
  }
`

const ContactInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 35px;

  &:nth-child(2) {
    margin-left: 15px;
  }
`
const ContactInfoLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    flex-wrap: wrap;
  }

  & > h6 {
    margin-right: 20px;

    &.service-hour {
      min-width: 150px;
    }

    &.contact-number {
      margin-right: 0;
      width: 210px;
    }
  }
`

const StyledP = styled.p`
  margin: 0;
  line-height: 1.4rem;
`
