import React from "react"

function FeatherClock() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.108"
      height="20.108"
      viewBox="0 0 20.108 20.108"
    >
      <g
        fill="none"
        stroke="#34a21f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        data-name="Icon feather-clock"
        transform="translate(1 1)"
      >
        <path
          d="M18.108 9.054A9.054 9.054 0 119.054 0a9.054 9.054 0 019.054 9.054z"
          data-name="Path 171346"
        ></path>
        <path d="M9.054 3.622v5.432l3.622 1.811" data-name="Path 171347"></path>
      </g>
    </svg>
  )
}

export default FeatherClock
