import React from "react"
import styled from "styled-components"

const ContactUsInputField = ({
  label,
  name = "",
  error = false,
  setState = () => {},
}) => {
  return (
    <Container>
      <h6>{label}</h6>
      <Input
        name={name}
        className={error ? "error" : ""}
        onChange={(e) => setState(e.target.value)}
      ></Input>
    </Container>
  )
}

export default ContactUsInputField

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 10px;
`
const Input = styled.input`
  min-width: 400px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.brandGrey};
  border: none;
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 20px;

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.brandHover};
    outline: none;

    &.error {
      border: 1px solid red;
    }
  }

  &.error {
    border: 1px solid red;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    min-width: unset;
  }
`
