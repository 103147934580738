import React from "react"

import Layout from "../../theme/layout"
import SEO from "../../components/header/seo"
import ContactUsWrapperSingle from "../../components/contact-us/contact-us-wrapper-single"

const ContactUsPage = ({ pageContext, location }) => {
  return (
    <Layout pathContext={pageContext} location={location}>
      <SEO title={"Contact us"} location={location} />
      <ContactUsWrapperSingle
        contactInfo={pageContext}
        className="page-fullwidth-section"
      ></ContactUsWrapperSingle>
    </Layout>
  )
}

export default ContactUsPage
