import React from "react"

function FeatherPhone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.009"
      height="20.045"
      viewBox="0 0 20.009 20.045"
    >
      <path
        fill="none"
        stroke="#34a21f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19.008 14.509v2.716a1.811 1.811 0 01-1.975 1.811 17.918 17.918 0 01-7.814-2.78 17.655 17.655 0 01-5.432-5.432 17.918 17.918 0 01-2.78-7.85A1.811 1.811 0 012.81 1h2.716a1.811 1.811 0 011.807 1.557A11.625 11.625 0 007.971 5.1a1.811 1.811 0 01-.407 1.91l-1.15 1.15a14.487 14.487 0 005.432 5.432l1.15-1.15a1.811 1.811 0 011.91-.407 11.625 11.625 0 002.544.634 1.811 1.811 0 011.558 1.84z"
        data-name="Icon feather-phone"
      ></path>
    </svg>
  )
}

export default FeatherPhone
