import React from "react"

function FeatherMail() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.894"
      height="16.487"
      viewBox="0 0 20.894 16.487"
    >
      <g
        fill="none"
        stroke="#34a21f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        data-name="Icon feather-mail"
        transform="translate(1.393 1)"
      >
        <path
          d="M1.811 0H16.3a1.816 1.816 0 011.811 1.811v10.865a1.816 1.816 0 01-1.811 1.811H1.811A1.816 1.816 0 010 12.676V1.811A1.816 1.816 0 011.811 0z"
          data-name="Path 171348"
        ></path>
        <path
          d="M18.108 1.811L9.054 8.149 0 1.811"
          data-name="Path 171349"
        ></path>
      </g>
    </svg>
  )
}

export default FeatherMail
