import React, { useEffect, useState } from "react"
import styled from "styled-components"
import ContactUsInputField from "./contact-us-input-field"
import ContactUsTextArea from "./contact-us-text-area"

const ContactUsForm = () => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()

    if (!/\S+@\S+\.\S+/.test(email)) {
      setError(true)
    } else {
      setError(false)
      event.currentTarget.submit()
    }
  }

  return (
    <Container>
      <StyledH4>Send us a message</StyledH4>
      <form
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name={"contact-us-form"}
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value={"contact-us-form"} />
        <InputContainer>
          <ContactUsInputField label={"Name"} name="Name"></ContactUsInputField>
          <ContactUsInputField
            label={"Email Address"}
            name="Email Address"
            error={error}
            setState={setEmail}
          ></ContactUsInputField>
        </InputContainer>
        <ContactUsTextArea label={"Message"} name="Message"></ContactUsTextArea>

        <StyledButton>
          <button type={"submit"}>Submit</button>
        </StyledButton>
      </form>
    </Container>
  )
}

export default ContactUsForm

const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    margin-top: 3rem;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
  }
`
const StyledH4 = styled.h4`
  margin-bottom: 20px;
`

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const StyledButton = styled.div`
  margin: 20px 0;
  width: 200px;
  height: 50px;

  button {
    width: 100%;
    heigth: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    margin: 20px auto;
  }
`
