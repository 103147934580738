import React from "react"

function FeatherMapPin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.297"
      height="21.919"
      viewBox="0 0 18.297 21.919"
    >
      <g
        fill="none"
        stroke="#34a21f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        data-name="Icon feather-map-pin"
        transform="translate(1 1)"
      >
        <path
          d="M16.3 8.149c0 6.338-8.149 11.77-8.149 11.77S0 14.487 0 8.149a8.15 8.15 0 1116.3 0z"
          data-name="Path 171344"
        ></path>
        <path
          d="M10.865 8.148a2.716 2.716 0 11-2.716-2.716 2.716 2.716 0 012.716 2.716z"
          data-name="Path 171345"
        ></path>
      </g>
    </svg>
  )
}

export default FeatherMapPin
