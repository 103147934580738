import React from "react"
import styled from "styled-components"

const ContactUsMap = ({ mapLink }) => {
  return (
    <>
      <StyledIframe
        src={mapLink}
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen
      />
    </>
  )
}

export default ContactUsMap

// ===============
//     STYLES
// ===============

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
`
